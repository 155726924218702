<template>
  <div>
    <p v-show="errorProfesor" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorProfesor }}
    </p>

    <v-card :loading="loadingProfesor" v-if="!errorProfesor">
      <v-card-title>
        <v-img contain max-height="150" src="../../assets/aula.png"> </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">
        {{ profesor.nombre }} {{ profesor.primerApellido }}
        {{ profesor.segundoApellido }}
      </h2>

      <br />

      <v-divider></v-divider>

      <v-card-title>
        Datos personales

        <EditarProfesor
          :profesor="profesor"
          @edit="getProfesor($route.params.id)"
        ></EditarProfesor>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Fecha de alta:</h3>
            <p>{{ new Date(profesor.fechaAlta).toLocaleString() }}</p>

            <h3>Nombre:</h3>
            <p>{{ profesor.nombre }}</p>

            <h3>Primer apellido:</h3>
            <p>{{ profesor.primerApellido }}</p>

            <h3>Segundo apellido:</h3>
            <p>{{ profesor.segundoApellido }}</p>

            <h3>DNI:</h3>
            <p>{{ profesor.dni }}</p>

            <h3>Fecha de nacimiento:</h3>
            <p>{{ new Date(profesor.fechaNacimiento).toLocaleDateString() }}</p>
          </v-col>

          <v-col>
            <h3>Domicilio:</h3>
            <p>{{ profesor.domicilio }}</p>

            <h3>Teléfono:</h3>
            <p>{{ profesor.telefono }}</p>

            <h3>Correo:</h3>
            <p>{{ profesor.correo }}</p>

            <h3>Observaciones:</h3>
            <p>{{ profesor.observaciones }}</p>

            <h3>Última modificación:</h3>
            <p>
              {{ new Date(profesor.ultimaModificacion).toLocaleString() }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="mt-4">
        <v-btn color="red" @click="eliminarProfesor(profesor._id)">
          <v-icon>mdi-delete</v-icon>
          Eliminar profesor
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import EditarProfesor from './EditarProfesor';

export default {
  data() {
    return {
      profesor: {},
      errorProfesor: null,
      loadingProfesor: true,
    };
  },
  methods: {
    async getProfesor(id) {
      try {
        this.loadingProfesor = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/profesores?id=' + id);

        this.profesor = res.data;

        this.loadingProfesor = false;
      } catch (error) {
        this.loadingProfesor = false;

        this.errorProfesor = error.response.data;
      }
    },
    async eliminarProfesor(id) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/profesores/eliminar', {
          id,
        });
        this.$router.push({ name: 'Profesores' });
      } catch (error) {
        alert('Error - No se ha podido eliminar el profesor');
      }
    },
    async mail(correo) {
      location.href = `mailto:${correo}`;
    },
    async call(telefono) {
      location.href = `tel:${telefono}`;
    },
    async whatsapp(telefono) {
      window.open(`https://wa.me/34${telefono}`, '_blank');
    },
  },
  components: {
    EditarProfesor,
  },
  mounted() {
    this.getProfesor(this.$route.params.id);
  },
};
</script>
