<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small color="blue" class="ml-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar datos personales
      </v-card-title>
      <v-card-text>
        <v-text-field label="Nombre" v-model="profesor.nombre"></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="profesor.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo Apellido"
          v-model="profesor.segundoApellido"
        ></v-text-field>

        <v-text-field label="DNI" v-model="profesor.dni"></v-text-field>

        <v-menu
          ref="menu"
          v-model="fechaNacimiento"
          :close-on-content-click="false"
          :return-value.sync="profesor.fechaNacimiento"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="profesor.fechaNacimiento"
              label="Fecha de nacimiento"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="profesor.fechaNacimiento" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fechaNacimiento = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="profesor.fechaNacimiento = ''">
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(profesor.fechaNacimiento)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          label="Domicilio"
          v-model="profesor.domicilio"
        ></v-text-field>

        <v-text-field
          label="Teléfono"
          v-model="profesor.telefono"
        ></v-text-field>

        <v-text-field label="Correo" v-model="profesor.correo"></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="profesor.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarProfesor',
  props: ['profesor'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      fechaNacimiento: false,
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/profesores/editar', {
          id: this.profesor._id,
          nombre: this.profesor.nombre,
          primerApellido: this.profesor.primerApellido,
          segundoApellido: this.profesor.segundoApellido,
          dni: this.profesor.dni,
          fechaNacimiento: this.profesor.fechaNacimiento,
          domicilio: this.profesor.domicilio,
          telefono: this.profesor.telefono,
          correo: this.profesor.correo,
          observaciones: this.profesor.observaciones,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
